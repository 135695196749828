export const SidebarData = [
    {
        title: 'Piezas',
        path: '/piezas',
        cName: 'nav-text',
        permission: 0
    },
    {
        title: 'Borrado de piezas',
        path: '/borrado',
        cName: 'nav-text',
        permission: 0
    },
]