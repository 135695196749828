import { useState } from "react";
import React from "react";
import moment from 'moment';
import CustomDatePicker from "../CustomDatePicker";
import { useForm } from "react-hook-form";

export default function ExportForm({
    onSubmitExport
}) {
    const { 
        handleSubmit 
    } = useForm({
        defaultValues: {
            excel: ''
        }
    });

    const [startDateValue, setStartDateValue] = useState(null);
    const [endDateValue, setEndDateValue] = useState(null);

    const isExportDisabled = () => { 
        return (startDateValue !== null && endDateValue !== null) ? false : true ;
    }

    const handleSubmitExport = () => {
        const dataAux = {
            startDate: startDateValue ? moment(startDateValue).format('YYYY-MM-DD') : null,
            endDate: endDateValue ? moment(endDateValue).format('YYYY-MM-DD') : null,
        };

        onSubmitExport(dataAux);
    };

    return (
        <div className="w-100">
            <form className="form align-items-center mb-4" onSubmit={handleSubmit(handleSubmitExport)}>
                <div className="w-100 d-flex flex-column">
                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <div className="form-group me-3">
                            <CustomDatePicker 
                                label={"Fecha inicial"}
                                disableFuture={true}
                                minDateValue={null}
                                onChangeDate={setStartDateValue}
                            />
                        </div>
                        
                        <div className="form-group">
                            <CustomDatePicker 
                                label={"Fecha final"}
                                disableFuture={true}
                                minDateValue={startDateValue}
                                onChangeDate={setEndDateValue}
                            />
                        </div>
                    </div>
                </div>

                <div className="w-100 d-flex align-items-center justify-content-end mt-4">
                    <button 
                        type="submit" 
                        className="btn btn-primary" 
                        disabled={isExportDisabled()}>Exportar</button>
                </div>
            </form>
        </div>
    );
}