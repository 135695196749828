import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

export default function ScannerForm({
    onScan
}) {
    const [codigoPedido, setCodigoPedido] = useState('');
    const inputCodigo = useRef(null);

    const validateInput = (input) => { return /^[0-9]+$/.test(input); };
    const handleChangeInput = (e) => {
        setCodigoPedido(e.target.value)
    }

    const handleEnterInput = async (e) => {
        if (e.key === 'Enter') {
            if (!validateInput(codigoPedido)) {
                toast.error('El código del pedido debe contener solo números.');
                return;
            }

            setCodigoPedido('');

            if (inputCodigo.current) {
                inputCodigo.current.focus();
                inputCodigo.current.value = '';
            }

            onScan(codigoPedido);
        }
    };

    return (
        <div className='w-100 mt-3'>
            <div className='w-100 d-flex align-items-center justify-content-center my-4'>
                <div className="col-12 col-md-6">
                    <input 
                        type="text" 
                        className="form-control" 
                        defaultValue={codigoPedido} 
                        id="scanner" 
                        placeholder="Escanear código de barras"
                        onKeyDown={(e) => handleEnterInput(e)}
                        onChange={(e) => handleChangeInput(e)}
                        ref={inputCodigo}
                    />
                </div>
            </div>
        </div>
    );
}
