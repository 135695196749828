import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { useState } from 'react';

export default function InfoModal({ title, content, state, width, onClose }) {
  const [open, setOpen] = useState(state);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={width ? width : 'sm'}
      aria-labelledby="info-title"
      aria-describedby="info-description"
    >
      <DialogTitle id="info-title">
        <div className="w-100 d-flex align-items-center justify-content-between">
          <p className="text-uppercase fw-bold">{title}</p>

          <span title="cerrar" className="close" onClick={() => handleClose()}>
            <FontAwesomeIcon icon={faTimes} size="1x" color="#215732" />
          </span>
        </div>
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
    </Dialog>
  );
}
