import React, { memo, useEffect } from "react";
import { useState } from "react";
import { isEqual } from 'lodash';
import { SidebarData } from "./SidebarData";
import './Navbar.scss';
import logo from '../../../images/logo_white.png';
import UserAvatar from "./UserAvatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";

const Navbar = ({
    onLogout
}) => {
    let pathname = window.location.pathname === '/' ? 'home' : window.location.pathname.split('/')[1];
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();
    const [sideBar, setSidebar] = useState(false);

    useEffect(() => {
        
    }, []);
    
    const handleClickMenuItem = (path) => {
        navigate(path);
    }

    const showSidebar = (show) => { setSidebar(show); }

    const NavItem = memo(({children}) => {
        return (
            <span>
                {children}
            </span>
        )
    }, isEqual)

    return (
        <>
            <div className="navbar-sidebar">
                <Link to="#" className="menu-bars" onMouseEnter={() => showSidebar(true)}>
                    <FontAwesomeIcon icon={faBars} size="2x" color="#215732" />
                </Link>
            </div>
            <nav className={sideBar ? "nav-menu active" : "nav-menu"} onMouseLeave={() => showSidebar(false)}>
                <ul className="nav-menu-items">
                    <li>
                        <div className="w-100 d-flex align-items-center justify-content-between">
                            <div className="navbar-brand">
                                <img src={logo} className="brand" style={{height: '50px'}} alt="Recomotor" />
                            </div>
                            <UserAvatar 
                                style={{ cursor: 'pointer' }}
                                onLogout={onLogout} />
                        </div>
                    </li>
                    { 
                        currentUser?.rol_id !== 7 
                            ? SidebarData.map((item, index) => {
                                if(
                                    item.permission === currentUser?.rol_id || 
                                    (item.permission === 0) || 
                                    (currentUser?.rol_id === 1)  || 
                                    (item.title === 'Autorizaciones Credito' && currentUser.responsable) || 
                                    (item.title === 'Users' && currentUser.responsable) || 
                                    (item.title === 'Comerciales' && currentUser.responsable) || 
                                    (item.title === 'Finanzas' && currentUser.responsable) ||
                                    (item.title === 'Clientes' && ((currentUser?.rol_id === 4 || currentUser?.rol_id === 5) || currentUser.responsable))
                                ) {
                                    return (
                                        <NavItem key={index}>
                                            <span
                                                onClick={() => handleClickMenuItem(item.path)}
                                                className={`${item.cName} ${item.title.toLowerCase() === pathname ? "active" : ""}`}>
                                                    <li style={{ cursor: 'pointer' }}>
                                                        <span>{item.title}</span>
                                                    </li>
                                            </span>
                                        </NavItem>
                                )} else {
                                    return '';
                                }})
                        : <NavItem>
                                <span
                                    onClick={() => handleClickMenuItem('/')}
                                    className={`${'nav-text'} ${('Dashboard').toLowerCase() === pathname ? "active" : ""}`}>
                                        <li style={{ cursor: 'pointer' }}>
                                            <span>{'Dashboard'}</span>
                                        </li>
                                </span>
                            </NavItem>
                    }
                    
                </ul>
            </nav>
        </>
    );
}

export default memo(Navbar)