import moment from 'moment';
import ExcelJS from 'exceljs';

export const createPiezasExcel = async(
    piezas
) => {
    const validas = piezas?.filter(p => !p.eliminada);
    const eliminadas = piezas?.filter(p => p.eliminada);
    const workbook = new ExcelJS.Workbook();

    const validSheet = workbook.addWorksheet('Añadidas al inventario');
    validSheet.columns = [
        { header: '#', key: 'id_referencia_plataforma', width: 40, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
        { header: 'Fecha añadida', key: 'fecha', width: 40, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
        { header: 'Responsable', key: 'created_by', width: 40, style: { alignment: { vertical: 'middle', horizontal: 'center' }}}
    ];
    validSheet.getCell('A1').font = { bold: true };
    validSheet.getCell('B1').font = { bold: true };
    validSheet.getCell('C1').font = { bold: true };

    const deletedSheet = workbook.addWorksheet('Borradas');
    deletedSheet.columns = [
        { header: '#', key: 'id_referencia_plataforma', width: 40, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
        { header: 'Fecha eliminada', key: 'fecha_eliminada', width: 40, style: { alignment: { vertical: 'middle', horizontal: 'center' }}},
        { header: 'Responsable', key: 'deleted_by', width: 40, style: { alignment: { vertical: 'middle', horizontal: 'center' }}}
    ];
    deletedSheet.getCell('A1').font = { bold: true };
    deletedSheet.getCell('B1').font = { bold: true };
    deletedSheet.getCell('C1').font = { bold: true };
    
    validas.forEach(pieza => {
        validSheet.addRow({
            id_referencia_plataforma: pieza.id_referencia_plataforma,
            fecha: moment(pieza.fecha).format('DD-MM-YYYY HH:mm:ss'),
            created_by: pieza.responsable.nombre?.toUpperCase(),
        }).commit();
    });

    eliminadas.forEach(pieza => {
        deletedSheet.addRow({
            id_referencia_plataforma: pieza.id_referencia_plataforma,
            fecha_eliminada: moment(pieza.fecha_eliminada).format('DD-MM-YYYY HH:mm:ss'),
            deleted_by: pieza.responsableBorrado.nombre?.toUpperCase()
        }).commit();
    });

    return await workbook.xlsx.writeBuffer();
}