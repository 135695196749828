import { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import Home from './Home';
import Navbar from "./Navbar";
import Borrado from "./Borrado";

const Main = ({onLogout}) => {

    useEffect(() => {
    }, []);

      return (
        <div className="dashboard-container">
            <Navbar 
                onLogout={onLogout} />
            
            <Routes>
                <Route path='*' exact={true} element={<Navigate to="/" />}></Route>
                <Route path="/" exact element={<Home />}></Route>
                <Route path="/borrado" exact element={<Borrado />}></Route>
            </Routes>
        </div> 
    );
}

export default Main