import React, { useEffect, useState } from "react";
import { exportPiezas, getLastEscaneadas, registrarPieza } from "../shared/services/piezas";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faPlus } from "@fortawesome/free-solid-svg-icons";
import InfoModal from "../shared/components/InfoModal";
import ScannerForm from "../shared/components/ScannerForm";
import { toast } from "react-toastify";
import { setErrorMessage } from "../shared/helpers/functionalities";
import ExportForm from "../shared/components/ExportForm";
import { createPiezasExcel } from "../shared/helpers/exportPiezas";
import LoadingBackdrop from "../shared/components/LoadingBackdrop";

export default function Home() {
    const user = JSON.parse(localStorage.getItem('user'));
    const [list, setList] = useState([]);
    const [openScan, setOpenScan] = useState(false);
    const [openExport, setOpenExport] = useState(false);
    const [loading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchPiezasEscaneadas();
    }, []);

    useEffect(() => {
    }, [list]);

    const fetchPiezasEscaneadas = async() => {
        setIsLoading(true);
        const response = await getLastEscaneadas();

        if(response && response.success) {
            setIsLoading(false);
            setList(response.piezas);
        }
    }

    const handleOpenScan = () => setOpenScan(true);
    const handleCloseScan = () => setOpenScan(false);
    const handleAddPieza = async(idRefPlat) => {
        setIsLoading(true);
        const response = await registrarPieza({
            id_referencia_plataforma: idRefPlat,
            user_id: user.id
        })
        .catch(function (error) {
            toast.error(setErrorMessage(error))
        });

        if(response && response.success) {
            setIsLoading(false);
            toast.success(response.message);
            fetchPiezasEscaneadas()
        }
    }

    const handleOpenExport = () => setOpenExport(true);
    const handleCloseExport = () => setOpenExport(false);
    const handleExport = async(data) => {
        setIsLoading(true);
        const startDate = moment(data.startDate).format('DD-MM-YYYY');
        const endDate = moment(data.endDate).format('DD-MM-YYYY');
        const response = await exportPiezas(data)
        .catch(function (error) {
            toast.error(setErrorMessage(error))
        });

        if(response && response.success) {
            setIsLoading(false);

            if(response.piezas?.length > 0) {
                const excel = await createPiezasExcel(
                    response.piezas
                );

                toast.success('Excel generado correctamente!')
                const url = window.URL.createObjectURL(new Blob([excel]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'piezas_' + startDate + '_' + endDate + '.xlsx');
                document.body.appendChild(link);
                link.click();
            } else {
                toast.error('No hay piezas a exportar!')
            }          
        } else {
            toast.error('Ups! Algo ha ido mal...')
        }
        
        /*
        if(excelResult) {
            toast.success('Excel generado correctamente!')
            const url = window.URL.createObjectURL(new Blob([excelResult]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'piezas_' + startDate + '_' + endDate + '.xlsx');
            document.body.appendChild(link);
            link.click();
        } else {
            toast.error('Ups! Algo ha ido mal...')
        }*/
    }

    return (
        <div className="content">
            <div className="w-100 mt-4">
                <div className="w-100 d-flex align-items-center justify-content-end mb-4">
                    <button className="btn btn-add me-3" onClick={() => handleOpenExport()}>
                        <span className="add">
                            <FontAwesomeIcon icon={faFileExcel} size="1x" className="action" color="#215732" />
                        </span>
                        Exportar
                    </button>

                    <button className="btn btn-add" onClick={() => handleOpenScan()}>
                        <span className="add">
                            <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                        </span>
                        Nuevo
                    </button>
                </div>

                <div className="w-100 table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>ID pieza</th>
                                <th className="text-center">fecha escaneo</th>
                                <th className="text-center">responsable</th>
                            </tr>
                        </thead>

                        <tbody>
                            { list?.length > 0 ?
                                list?.map((pieza) => {
                                    return (
                                        <tr key={'pieza-' + pieza.id}>
                                            <td>{pieza.id_referencia_plataforma}</td>
                                            <td className="text-center">
                                                {moment(pieza.updatedAt).format('DD-MM-YYYY HH:mm:ss')}
                                            </td>
                                            <td className="text-uppercase text-center">
                                                {pieza.responsable.username}
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <tr>
                                    <td colSpan={4} className="text-center">No hay piezas escaneadas.</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            { (openScan) &&
                <InfoModal 
                    title={"Escaneo de piezas"}
                    content={
                        <ScannerForm 
                            onScan={handleAddPieza}
                        />
                    }
                    state={openScan}
                    width={"sm"}
                    onClose={handleCloseScan}
                />
            }

            { (openExport) &&
                <InfoModal 
                    title={"Exportar datos"}
                    content={
                        <ExportForm 
                            onSubmitExport={handleExport}
                        />
                    }
                    state={openExport}
                    width={"sm"}
                    onClose={handleCloseExport}
                />
            }

            { (loading) &&
                <LoadingBackdrop />
            }
        </div>
    );
}