import React, { useEffect, useState } from "react";
import { eliminarPiezas, getPiezaByIdRef } from "../shared/services/piezas";
import { toast } from "react-toastify";
import { setErrorMessage } from "../shared/helpers/functionalities";
import ScannerForm from "../shared/components/ScannerForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import LoadingBackdrop from "../shared/components/LoadingBackdrop";

export default function Borrado() {
    const user = JSON.parse(localStorage.getItem('user'));
    const [list, setList] = useState([]);
    const [loading, setIsLoading] = useState(false);

    useEffect(() => {
    }, [list]);

    const handleScannedPieza = async(idRefPlat) => {
        setIsLoading(true);
        const response = await getPiezaByIdRef(idRefPlat)
        .catch(function (error) {
            toast.error(setErrorMessage(error))
        });

        if(response && response.success) {
            setIsLoading(false);
            if(list.find(p => p === idRefPlat)) {
                toast.error('Esta pieza ya está escaneada!');
                return;
            }
    
            const newList = [...list];
            newList.push(idRefPlat);
    
            setList(newList);
        }
    }

    const handleDeleteFromList = (index) => {
        const newList = [...list];
        newList.splice(index, 1);

        setList(newList);
    }
   
    const handleDeletePiezas = async() => {
        setIsLoading(true);
        const response = await eliminarPiezas({
            piezas: list, 
            user_id: user.id
        })
        .catch(function (error) {
            toast.error(setErrorMessage(error))
        });

        if(response && response.success) {
            setIsLoading(false);
            toast.success(response.message);
            setList([]);
        }
    }

    return (
        <div className="content">
            <div className="w-100 d-flex flex-column align-items-center justify-content-center">
                <ScannerForm 
                    onScan={handleScannedPieza}
                />

                <div className="w-100 table-responsive mt-5">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>ID pieza</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            { list?.length > 0 ?
                                list?.map((idRefPlat, i) => {
                                    return (
                                        <tr key={'pieza-' + i}>
                                            <td>{idRefPlat}</td>
                                            <td className="text-uppercase text-center">
                                                <span title="Eliminar" style={{cursor: 'pointer'}} onClick={() => handleDeleteFromList(i)}>
                                                    <FontAwesomeIcon icon={faTrash} size="1x" className="action" color="#dc3545" />
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <tr>
                                    <td colSpan={4} className="text-center">No hay piezas escaneadas.</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>

                <div className="mt-5">
                    <button 
                        type="button" 
                        className="btn btn-danger"
                        onClick={(e) => handleDeletePiezas()}
                    >Eliminar piezas</button>
                </div>
            </div>

            { (loading) &&
                <LoadingBackdrop />
            }
        </div>
    );
}