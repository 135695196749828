import React from "react";
import { useState } from "react";
import { Avatar, Menu, MenuItem } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";

export default function UserAvatar({
    onLogout
}) {

    const currentUser = JSON.parse(localStorage.getItem('user'));
    const [anchorEl, setAnchorEl] = useState(null);

    const stringAvatar = (name) => {
        return {
          sx: {
            bgcolor: stringToColor(currentUser.username),
          },
          children: `${name.split('')[0]}`,
        };
    }

    const stringToColor = (string) => {
        let hash = 0;
        let i;
        let color = '#';
      
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.substr(-2);
        }
        /* eslint-enable no-bitwise */
      
        return color;
    }
    
    const handleMenu = (event) => { setAnchorEl(event.currentTarget); };
    const handleLogout = () => { onLogout(); };
    const handleClose = () => { setAnchorEl(null); };

    return (
        <div>
            <Avatar
                style={{ cursor: 'pointer' }} 
                {...stringAvatar(currentUser?.username?.toUpperCase())} 
                aria-label="Mi cuenta"
                aria-controls="user-menu"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit" />
            <Menu
                id="user-menu"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                    <MenuItem onClick={handleLogout}>
                        <FontAwesomeIcon icon={faRightFromBracket} size="1x" className="action me-3" color="#215732" />
                        <small>Cerrar sesión</small>
                    </MenuItem>
            </Menu>
        </div>
    );
}
