import axios from 'axios';
import config from '../config';

const API = axios.create({
  baseURL: config.recomotor.baseUrl,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  },
});

API.interceptors.request.use(
  (config) => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser && currentUser.token) {
      config.headers.authorization =  `Bearer ${currentUser.token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

API.interceptors.response.use((response) => {
  return response
}, async function (error) {
  
  // Logout user if token refresh didn't work or user is disabled
  if (error.response.status === 403 && error.response.data === 'You dont have access to this resource') {
    localStorage.setItem('user', null);
    window.location.href="/";

    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

    return new Promise((resolve, reject) => {
      reject(error);
    });
  
});

export default API;