export const delay = ms => new Promise(res => setTimeout(res, ms));

export const headers = {
    headers: {
      "Content-Type": "multipart/form-data",
    }
};

export const setErrorMessage = (error) => {
    if (error.response) {
        return error.response.data.message;
    } else if (error.request) {
        return 'Ups... algo ha ido mal';
    } else {
        return error.message;
    }
}