import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import './styles/main.scss';
import Login from './views/Login';
import Main from './views/Main';
import useToken from './useToken';
import { ToastContainer } from "react-toastify";

function App() {
  const navigate = useNavigate();
  const { token, setToken } = useToken();

  const handleLogout = () => {
    const logoutUser = async () => {
      setToken(null);
        localStorage.clear();
        navigate('/login', { replace: true });
    }
    logoutUser();
  }

  return (
    <div className="w-100 h-100">
      <Routes>
        <Route path="/" element={
            !token ? 
              <Login setToken={setToken} /> 
            : <Main onLogout={handleLogout} />
          }
        ></Route>

        <Route path="/login" element={
            !token ? 
              <Login setToken={setToken} /> 
            : <Main onLogout={handleLogout} />
          }
        ></Route>

        <Route path='*' exact={true} element={
          !token ? 
              <Login setToken={setToken} /> 
            : <Main onLogout={handleLogout} />
        } />
        <Route path='*' exact={true} element={<Navigate to="/" />}></Route>
      </Routes>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
