import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes from 'prop-types';
import './login.scss';
import {authenticate} from '../../services/auth';
import logo from '../../images/logo.png';
import { toast } from 'react-toastify';
import config from '../../config';
import { setErrorMessage } from "../Main/shared/helpers/functionalities";

export default function Login({setToken}) {
    let navigate = useNavigate();
    let location = useLocation();
    let from = location.state?.from?.pathname || "/";
    const email = useFormInput('');
    const password = useFormInput('');
    const [error, setError] = useState(null);
    const handleLogin = async (e) => {
        e.preventDefault();
        setError(null);

        const response = await authenticate({
            username: email.value,
            password: password.value
        }).catch(function (error) {
            toast.error(setErrorMessage(error))
        });

        if(response && response.success) {
            setToken(response.user);
            navigate(from, { replace: true });
        }
    };

    return (
        <div className="container w-100 h-100 d-flex align-items-center justify-content-center">
            <div className="col-md-7 col-lg-5 py-4 px-5">
                <img src={logo} className="logo d-block m-auto my-4" alt="Recomotor" />
                
                <form className="form d-flex flex-column align-items-stretch pt-3 pb-4" autoComplete="on" onSubmit={handleLogin}>
                    <div className="form-group mb-3">
                        <input type="text" className="form-control" placeholder="Email o username" {...email} />
                    </div>

                    <div className="form-group">
                        <input type="password" className="form-control" placeholder="Password" {...password} />
                    </div>

                    <button type="submit" className="btn btn-primary mt-4">Login</button>
                </form>

                {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
            </div>

            <footer>
                <span>{config.app.version}</span>
            </footer>

            
        </div>
    );
}

const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);
   
    const handleChange = e => {
      setValue(e.target.value);
    }
    return {
      value,
      onChange: handleChange
    }
  }

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}