import API from '../../../../services/api';

export const getPiezasEscaneadas = async (page = 1, status) => {
    const pagination = '?page=' + page;
    const statusItem = '&status=' + status;
    return await API.get('/piezas' + pagination + statusItem).then(res => res.data);
}

export const getLastEscaneadas = async () => {
    return await API.get('/piezas/last').then(res => res.data);
}

export const getPiezaByIdRef = async (idRef) => {
    return await API.get('/piezas/' + idRef).then(res => res.data);
}

export const exportPiezas = async (filters) => {
    return API.post('/piezas/export', filters).then(res => res.data);
}

export const registrarPieza = async (pieza) => {
    return API.post('/piezas', pieza).then(res => res.data);
}

export const eliminarPiezas = async (piezas) => {
    return API.post('/piezas/delete', piezas).then(res => res.data);
}